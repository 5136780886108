import { default as abouteHAWVfBtNiMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/about.vue?macro=true";
import { default as dataviewVOiF81i6dhMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/dataview.vue?macro=true";
import { default as imagesnuUOZBqAIpMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/images.vue?macro=true";
import { default as tasksEXdC3bV1GlMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/tasks.vue?macro=true";
import { default as usersumHcXdERccMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/users.vue?macro=true";
import { default as adminhsPvgZf9rJMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/admin.vue?macro=true";
import { default as indexhnOO6Ha5NkMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/index.vue?macro=true";
import { default as issuespsmdmhe1QyMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/issues.vue?macro=true";
import { default as logincuQukh1cq5Meta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/login.vue?macro=true";
import { default as logoutXBKeyWFsJnMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/logout.vue?macro=true";
import { default as indexn0qZZeRtmKMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/index.vue?macro=true";
import { default as _91imageId_93E7y9giJbuzMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/task-[taskId]/[imageId].vue?macro=true";
import { default as indexwryk5H6QVxMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/task-[taskId]/index.vue?macro=true";
import { default as task_45_91taskId_932OxJ7kJvloMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/task-[taskId].vue?macro=true";
import { default as pathappIekV1bK5aJMeta } from "/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp.vue?macro=true";
export default [
  {
    name: abouteHAWVfBtNiMeta?.name ?? "about",
    path: abouteHAWVfBtNiMeta?.path ?? "/about",
    meta: abouteHAWVfBtNiMeta || {},
    alias: abouteHAWVfBtNiMeta?.alias || [],
    redirect: abouteHAWVfBtNiMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/about.vue").then(m => m.default || m)
  },
  {
    name: adminhsPvgZf9rJMeta?.name ?? "admin",
    path: adminhsPvgZf9rJMeta?.path ?? "/admin",
    meta: adminhsPvgZf9rJMeta || {},
    alias: adminhsPvgZf9rJMeta?.alias || [],
    redirect: adminhsPvgZf9rJMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: dataviewVOiF81i6dhMeta?.name ?? "admin-dataview",
    path: dataviewVOiF81i6dhMeta?.path ?? "dataview",
    meta: dataviewVOiF81i6dhMeta || {},
    alias: dataviewVOiF81i6dhMeta?.alias || [],
    redirect: dataviewVOiF81i6dhMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/dataview.vue").then(m => m.default || m)
  },
  {
    name: imagesnuUOZBqAIpMeta?.name ?? "admin-images",
    path: imagesnuUOZBqAIpMeta?.path ?? "images",
    meta: imagesnuUOZBqAIpMeta || {},
    alias: imagesnuUOZBqAIpMeta?.alias || [],
    redirect: imagesnuUOZBqAIpMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/images.vue").then(m => m.default || m)
  },
  {
    name: tasksEXdC3bV1GlMeta?.name ?? "admin-tasks",
    path: tasksEXdC3bV1GlMeta?.path ?? "tasks",
    meta: tasksEXdC3bV1GlMeta || {},
    alias: tasksEXdC3bV1GlMeta?.alias || [],
    redirect: tasksEXdC3bV1GlMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/tasks.vue").then(m => m.default || m)
  },
  {
    name: usersumHcXdERccMeta?.name ?? "admin-users",
    path: usersumHcXdERccMeta?.path ?? "users",
    meta: usersumHcXdERccMeta || {},
    alias: usersumHcXdERccMeta?.alias || [],
    redirect: usersumHcXdERccMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/admin/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexhnOO6Ha5NkMeta?.name ?? "index",
    path: indexhnOO6Ha5NkMeta?.path ?? "/",
    meta: indexhnOO6Ha5NkMeta || {},
    alias: indexhnOO6Ha5NkMeta?.alias || [],
    redirect: indexhnOO6Ha5NkMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: issuespsmdmhe1QyMeta?.name ?? "issues",
    path: issuespsmdmhe1QyMeta?.path ?? "/issues",
    meta: issuespsmdmhe1QyMeta || {},
    alias: issuespsmdmhe1QyMeta?.alias || [],
    redirect: issuespsmdmhe1QyMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/issues.vue").then(m => m.default || m)
  },
  {
    name: logincuQukh1cq5Meta?.name ?? "login",
    path: logincuQukh1cq5Meta?.path ?? "/login",
    meta: logincuQukh1cq5Meta || {},
    alias: logincuQukh1cq5Meta?.alias || [],
    redirect: logincuQukh1cq5Meta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutXBKeyWFsJnMeta?.name ?? "logout",
    path: logoutXBKeyWFsJnMeta?.path ?? "/logout",
    meta: logoutXBKeyWFsJnMeta || {},
    alias: logoutXBKeyWFsJnMeta?.alias || [],
    redirect: logoutXBKeyWFsJnMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: pathappIekV1bK5aJMeta?.name ?? undefined,
    path: pathappIekV1bK5aJMeta?.path ?? "/pathapp",
    meta: pathappIekV1bK5aJMeta || {},
    alias: pathappIekV1bK5aJMeta?.alias || [],
    redirect: pathappIekV1bK5aJMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp.vue").then(m => m.default || m),
    children: [
  {
    name: indexn0qZZeRtmKMeta?.name ?? "pathapp",
    path: indexn0qZZeRtmKMeta?.path ?? "",
    meta: indexn0qZZeRtmKMeta || {},
    alias: indexn0qZZeRtmKMeta?.alias || [],
    redirect: indexn0qZZeRtmKMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/index.vue").then(m => m.default || m)
  },
  {
    name: task_45_91taskId_932OxJ7kJvloMeta?.name ?? undefined,
    path: task_45_91taskId_932OxJ7kJvloMeta?.path ?? "task-:taskId()",
    meta: task_45_91taskId_932OxJ7kJvloMeta || {},
    alias: task_45_91taskId_932OxJ7kJvloMeta?.alias || [],
    redirect: task_45_91taskId_932OxJ7kJvloMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/task-[taskId].vue").then(m => m.default || m),
    children: [
  {
    name: _91imageId_93E7y9giJbuzMeta?.name ?? "pathapp-task-taskId-imageId",
    path: _91imageId_93E7y9giJbuzMeta?.path ?? ":imageId()",
    meta: _91imageId_93E7y9giJbuzMeta || {},
    alias: _91imageId_93E7y9giJbuzMeta?.alias || [],
    redirect: _91imageId_93E7y9giJbuzMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/task-[taskId]/[imageId].vue").then(m => m.default || m)
  },
  {
    name: indexwryk5H6QVxMeta?.name ?? "pathapp-task-taskId",
    path: indexwryk5H6QVxMeta?.path ?? "",
    meta: indexwryk5H6QVxMeta || {},
    alias: indexwryk5H6QVxMeta?.alias || [],
    redirect: indexwryk5H6QVxMeta?.redirect,
    component: () => import("/home/colin/cw-stage/cancer-not-cancer/client/pages/pathapp/task-[taskId]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]